.home_portfolio_section {
  background-color: #0a0d10;
}

.video_content {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.alioth-personal-head {
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 700px;
}

#bgvid {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.aph-image {
  position: absolute;
  bottom: 0;
  width: 35%;
  left: 50%;
  -webkit-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  transform: translatex(-50%);
  z-index: 5;
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
}

.aph-image img {
  width: 100%;
}

.aph-details {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 84%;
  height: 200px;
}

.aph-name {
  font-size: 220px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 6;
  pointer-events: none;
  overflow: hidden;
  font-weight: 100;
  letter-spacing: -0.06em;
}

/*------------------------------------------------------------------
## Wall
-------------------------------------------------------------------**/

.project-image {
  display: none;
}

.portfolio-showcase.showcase-wall {
  position: relative;
  width: 100%;
  height: 100vh;
}

.wall-projects {
  position: absolute;
  top: 57%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.wall-projects-top,
.wall-projects-bottom {
  white-space: nowrap;
  display: inline-block;
  width: auto;
}

.wall-projects-top {
  margin-bottom: 30px;
  -webkit-transform: translateX(10%);
  -ms-transform: translateX(10%);
  transform: translateX(10%);
}

.wall-projects-bottom {
  float: right;
  -webkit-transform: translateX(-10%);
  -ms-transform: translateX(-10%);
  transform: translateX(-10%);
}

.wall-project {
  display: inline-block;
}

.wall-project a {
  text-decoration: none;
  color: #191b1d;
  display: block;
}

.showcase-wall.dark .wall-project a,
body.dark .wall-project a {
  color: #fff;
}

.wall-project .project-title {
  font-size: 80px;
  font-weight: 100;
  letter-spacing: -0.07em;
  line-height: 80px;
  overflow: visible;
}

.wall-project .project-title::after {
  content: "/";
  opacity: 0.3;
  margin: 0 30px;
}

.wall-project .project-title::before {
  content: attr(data-index);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  display: inline-block;
  line-height: 20px;
  width: 20px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  color: rgba(25, 27, 29, 0.6);
}

.showcase-wall.dark .wall-project .project-title::before,
body.dark .wall-project .project-title::before {
  color: hsla(0, 0%, 100%, 0.2);
}

.wall-project:last-child .project-title::after {
  display: none;
}

#images-canvas {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.wall-projects.on-hover .wall-project {
  opacity: 0.2;
}

.wall-projects.on-hover .wall-project.hovered {
  opacity: 1;
}

.wall-image-fix.image-1 {
  visibility: visible !important;
  opacity: 1 !important;
}

.wall-images {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 70%;
  z-index: -2;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
}

.wall-image-fix {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  visibility: hidden;
}

.wall-images img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
}

.showcase-wall .circular-button {
  position: relative;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  display: block;
  left: 0;
  bottom: 0;
}

.wall-drag {
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 10%;
  background: rgba(25, 27, 29, 0.3);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

body.dark .wall-drag,
.showcase-wall.dark .wall-drag {
  background: hsla(0, 0%, 100%, 0.2);
}

span.wall-prog {
  position: absolute;
  left: 0;
  width: 0%;
  height: 1px;
  background: #191b1d;
  z-index: 6;
}

body.dark span.wall-prog,
.showcase-wall.dark span.wall-prog {
  background: #fff;
}

.alioth-personal-header.dark .aph-name,
body.dark .aph-name {
  color: #fff;
}

.aph-name.back {
  z-index: 4;
}

.aph-name .name-back {
  opacity: 0;
  visibility: hidden;
}

.aph-name.back .name-front {
  opacity: 0;
  visibility: hidden;
}

.aph-name.back .name-back {
  opacity: 1;
  visibility: visible;
}

.aph-welc {
  position: absolute;
  top: 0px;
  left: 15%;
  font-size: 30px;
  font-weight: 200;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  overflow: hidden;
}

.aph-sub-text {
  position: absolute;
  right: 0;
  max-width: 30%;
  -webkit-transform: translateY(200px);
  -ms-transform: translateY(200px);
  transform: translateY(200px);
  font-size: 15px;
  font-weight: 200;
  line-height: 22px;
  bottom: -20px;
}

.aph_sub_line {
  overflow: hidden;
}

.aph_sub_line span {
  display: block;
}

body.dark .aph-sub-text,
.alioth-personal-header.dark .aph-sub-text,
.alioth-personal-header.dark .aph-welc,
body.dark .aph-welc {
  color: rgba(255, 255, 255, 1);
}

.video_content .circular-button {
  position: absolute;
  bottom: 10%;
  left: 20%;
  background: #191b1d;
  border-radius: 50%;
  text-decoration: none;
  color: #fff;
}

.circular-button.dark,
body.dark .circular-button {
  color: #fff;
  background: #101010;
}

.circular-button span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 2;
  opacity: 1;
}

a.circular-button::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 0%;
  background: #ebebeb;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  z-index: 1;
}

a.circular-button.dark::after,
body.dark a.circular-button::after {
  background: #fff;
}

a.circular-button:hover::after {
  width: 100%;
  height: 100%;
}

a.circular-button:hover {
  color: #191b1d;
}

a.circular-button.dark:hover,
body.dark a.circular-button:hover {
  color: #191b1d;
}

.circular-button span i {
  display: block;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .video_content .circular-button {
    display: none;
  }

  .footer-widget,
  .footer-widget p,
  .footer_credit_left p,
  .footer_credit_right p {
    text-align: center;
  }

  h1.medium-title {
    font-size: 35px;
    font-style: normal;
    font-weight: 200;
    line-height: 50px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  h1.small-title {
    font-size: 35px;
    font-style: normal;
    font-weight: 200;
    line-height: 50px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 0px;
  }

  .aph-welc {
    left: 0;
    font-size: 25px;
    width: 100%;
    text-align: center;
  }

  .aph-name {
    font-size: 100px;
    top: 50%;
  }

  .aph-sub-text {
    max-width: 100%;
    font-size: 15px;
    font-weight: 200;
    line-height: 22px;
    bottom: 30px;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .aph-name {
    font-size: 140px;
  }
}

@media only screen and (max-width: 400px) {
  .aph-name {
    font-size: 80px;
  }
}

.testimonials {
  text-align: center;
  margin: 10rem 0rem;
}

.testimonials-header {
  margin: 0rem 1rem;
  font-weight: 400;
}

.testimonials-img-ctn {
  width: 150px;
}

.testimonials-img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 3px 3px 30px 25px rgba(0, 0, 0, 0.69);
}

.testimonials-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;
  margin: 0 auto;
  width: 450px;
}

.testimonials-review h2 {
  font-weight: 400;
}

.testimonials-review > * {
  margin-bottom: 1rem;
}

/* CAROUSEL CONFIGURATION */
.carousel-indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.circle {
  background-color: hsla(0, 0%, 100%, 0.2);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}

.circle:hover {
  background-color: #b7babe;
}

.circle:hover .icon {
  color: black;
}

.icon-ctn-left {
  position: absolute;
  top: 50%;
  left: 10%;
  padding: 10px;
  z-index: 100;
  cursor: pointer;
}

.icon-ctn-right {
  position: absolute;
  top: 50%;
  right: 10%;
  padding: 10px;
  z-index: 100;

  cursor: pointer;
}

.icon {
  font-size: 30px;
}

.testimonial-comment {
  font-size: 1.5em;
  line-height: 1.5em;
}

.testimonial-comment:before {
  content: open-quote;
}

.testimonial-comment:after {
  content: close-quote;
}

.testimonial-comment:before,
.testimonial-comment:after {
  display: inline-block;
  vertical-align: bottom;
  color: #b7babe;
  font-size: 4em;
  top: 0.2em;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 600px) {
  .testimonials {
    text-align: center;
    margin: 5rem 0rem;
  }

  .testimonials-header {
    margin: 0rem 1rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: normal;
  }

  .circle {
    width: 30px;
    height: 30px;
  }

  .testimonials-img-ctn {
    width: 150px;
  }

  .testimonials-review {
    width: 250px;
  }

  .icon-ctn-left {
    left: 5%;
  }

  .icon-ctn-right {
    right: 5%;
  }

  .icon {
    font-size: 10px;
  }

  .testimonial-comment {
    font-size: 1em;
  }
}

.c-col-12.no-gap{
margin-bottom:0px !important;
}

#canvas-twisted{
	display:none;
}


@media only screen and (max-width: 768px) {

	.showcase-slideshow-2-wrapper {
        max-width: 100%;
        width: 100%;
        left: 0;
        height: 100%;
    }
    body.dark .ss2-project-cat{
        color: hsl(0deg 0% 100%);
    }
	.ss2-images {
			right: 0;
			width: 100%;
			height: 100%;
			-webkit-transform: none;
			-ms-transform: none;
			transform: none;
			top: 0;
			z-index: -1
		}

    .ss2-project-title {
        font-size:48px;
        line-height: 55px;
    }
    /* .ss2-project:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(0 0 0 / 28%);
      } */
      .ss2-project-image img {
        object-fit: cover;
    }
      .slide-bgimg:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(0 0 0 / 28%);
    }
    .ss2-project-meta {
        left: 10%;
        width: 80%
    }

    .ss2-back-texts {
        display: none
    }

    .ss2-dots {
        display: none
    }

    .ss2-project-excerpt {
        display: none
    }

    .ss2-nav {
        position: absolute;
        bottom: 15%;
        right: 5%;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }

    .ss2-button {
        bottom: 5%;
        opacity: .6
    }

    .portfolio-showcase.showcase-slideshow-v2 .plyr__video-wrapper {
        width: calc((100vh) * 1.77777778)
    }

    .fs-title {
        font-size: 80px;
        line-height: 95px
    }

    .fs-project-dets {
        width: 80%;
    }

    .fs-button {
        right: 10%;
        top: 30%
    }

    .showcase-slideshow-wrapper {
        width: 50%
    }
}